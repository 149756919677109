import React from 'react';
import {
  Container,
  Typography,
  Grid,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';
import theme from 'libs/theme/theme';

const RightsAndObligationsOfConsumers = () => {
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const c = useStyles();

  return (
    <Container
      maxWidth="lg"
      className="lg-mg-bottom, padding-spacing textColor "
    >
      <Grid container className="bkg-cover mb4">
        <Grid item xs={12} className={'padding-spacing-2'}>
          <Typography variant="h2" gutterBottom={true} className="title">
            Prava i obaveze potrošača
          </Typography>
          <Typography variant="h1" gutterBottom={true} className="title">
            Obaveštenje o pravima i potrebama potrošača
          </Typography>

          <Typography>
            Prodavac je dužan da potrošača (kupca) upozna sa osnovnim obeležjima
            robe (proizvoda) koju kupuje, o ceni, načinu plaćanja, načinu i roku
            isporuke i načinu izvršenja drugih ugovornih obaveza, o postojanju i
            uslovima postprodajnih usluga i garancijama, o postojanju zakonske
            odgovornosti Prodavca zbog nesaobraznosti robe ugovoru, o načinu
            izjavljivanja reklamacije, a naročito o mestu prijema i načinu
            postupanja po njima, kao i uslovima koji se odnose na ostvarivanje
            prava potrošača po osnovu saobraznosti. Ako isporučena roba nije
            saobrazna ugovoru, Potrošač nakon što obavesti Prodavca o
            nesaobraznosti, ima pravo da zahteva od Prodavca da otkloni
            nesaobraznosti zamenom, odnosno da zahteva odgovarajuće umanjenje
            cene ili da raskine ugovor u pogledu te robe. Ako otklanjanje
            nesaobraznosti nije moguće, Potrošač ima pravo da zahteva
            odgovarajuće umanjenje cene ili raskid ugovora.
            <br />
            <br />
            Potrošač može da izjavi reklamaciju radi ostvarivanja svojih prava
            iz usled nesaobraznosti robe ugovoru i prava po osnovu garancije,
            kao i zbog pogrešno obračunate cene i drugih nedostataka, na način
            koji su opisane u obaveštenju o načinu i mestu primanja reklamacije.
            <br />
            <br />
            Prodavac je dužan da bez odlaganja, a najkasnije u roku od osam dana
            od dana prijema reklamacije, pisanim ili elektronskim putem odgovori
            Potrošaču na izjavljenu reklamaciju. Odgovor Prodavca na reklamaciju
            mora da sadrži odluku da li prihvata reklamaciju, izjašnjenje o
            zahtevu potrošača i konkretan predlog i rok za rešavanje
            reklamacije. Rok ne može da bude duži od 30 dana, od dana podnošenja
            reklamacije. Prodavac je dužan da postupi u skladu sa odlukom,
            predlogom i rokom za rešavanje reklamacije, ukoliko je dobio
            prethodnu saglasnost potrošača. S toga će potrošača svoju saglasnost
            dati u najkraćem mogućem roku od momenta prijema odgovora Prodavca
            na reklamaciju, kako bi Prodavac mogao da reši reklamaciju u gore
            spomenutom zakonskom roku.
            <br />
            <br />
            Neblagovremeno davanje saglasnoti potrošača smatraće se jednim od
            objektivnimh razloga zbog kojih Prodavac nije u mogucnosti da
            udovolji zahtevu potrošača u zakonskom roku. Ukoliko prodavac iz
            objektivnih razloga nije u mogucnosti da udovolji zahtevu potrošača
            u roku koji je dogovoren, dužan je da o produžavanju roka za
            rešavanje reklamacije obavesti potrošača i navede rok u kome će je
            rešiti, kao i da dobije njegovu saglasnost, koju će Potrošač dati u
            najkraćem mogućem roku. Produžavanje roka za rešavanje reklamacija
            moguće je samo jednom.
            <br />
            <br />
            Saobraznost se daje isključivo na ispravnost robe a ne na njenu
            funkcionalnost jer se podrazumeva da je Potrošač upoznat sa
            tehničkim karakteristikama proizvoda, pa se saobraznost odnosi na
            činjenicu da će izabrani proizvod zadovoljiti zahteve potrošača u
            okviru deklarisanih tehnickih mogucnosti propisanih od strane
            proizvođača. Od potrošača se očekuje da kupljeni proizvod ne izlaže
            štetnim dejstvima (ekstremno hladne ili ekstremno tople prostorije i
            sl.). Sve eventualne nepravilnosti prouzrokovane ovakvim tretmanom
            robe će se otklanjati isključivo na teret potrošača.
            <br />
            <br />
            Pravo na saobraznost nije moguće ostvariti ukoliko je do oštećenja
            ili kvara robe došlo usled transporta nakon isporuke, usled više
            sile (poplava, zemljotres, požar i sl.), usled nepravilnog
            korišćenja, usled nepridržavanja proizvođačkog uputstva i preporuka
            o upotrebi proizvoda, usled bilo kakvog mehaničkog oštećenja
            proizvoda koje je nastalo krivicom potrošača ili trećeg lica za koje
            Prodavac ne odgovara, ukoliko je proizvod vraćen bez fiskalnog
            računa ili drugog dokaza da je kupljen kod Prodavca. Potrošač je
            dužan da prilikom preuzimanja kupljenog proizvoda ustanovi
            kompletnost i fizičku neoštećenost proizvoda koje preuzima.
            <br />
            <br />
            Naknadne reklamacije koje se odnose na kompletnost i fizičku
            neoštećenost neće biti prihvaćene.
            <br />
            <br />
            Sve dodatne informacije Potrošač može dobiti pozivajući telefon{' '}
            <a href="tel:+381 643405076" className="title">
              +381 643405076
            </a>{' '}
            svakog radnog dana od 09h do 16h ili slanjem poruke na e-mail:{' '}
            <a
              href="mailto:plemenitaulja@gmail.com"
              className={isXs ? `${c.smallerFontOnXS} title` : 'title'}
            >
              plemenitaulja@gmail.com
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  smallerFontOnXS: {
    fontSize: theme.spacing(2),
  },
}));

export default RightsAndObligationsOfConsumers;
