import React from 'react';
import { Container, Typography, Grid } from '@material-ui/core';

const ReclamationAndReturnOfGoods = () => {
  return (
    <Container
      maxWidth="lg"
      className="lg-mg-bottom, padding-spacing textColor "
    >
      <Grid container className="bkg-cover mb4">
        <Grid item xs={12} className={'padding-spacing-2'}>
          <Typography variant="h2" gutterBottom={true} className="title">
            Reklamacija i povraćaj robe
          </Typography>

          <Typography gutterBottom={true}>
            U slučaju vraćanja robe i povraćaja sredstava kupcu koji je
            prethodno platio nekom od platnih kartica, delimično ili u celosti,
            a bez obzira na razlog vraćanja,{' '}
            <span className="title">
              Najbolje iz prirode DOO Prištinska 11A Kragujevac
            </span>{' '}
            je u obavezi da povraćaj vrši isključivo preko VISA, MasterCard i
            Maestro metoda plaćanja, što znači da će institucija na zahtev
            prodavca obaviti povraćaj sredstava na račun korisnika kartice.
          </Typography>

          <Typography gutterBottom={true}>
            Postupak rešavanja reklamacije sprovodi se na osnovu odredbi Zakona
            o zaštiti potrošača (“Sl.Glasnik RS” br. 62/2014) kao i odredbi
            Zakona o trgovini (“Sl.Glasnik RS” br. 53/10, 10/2013).
          </Typography>

          <br />
          <Typography gutterBottom={true}>
            Na zamenu neispravnih proizvoda imate pravo ako je na proizvodu
            jasno vidljivo da greška nije nastala nepravilnim korišćenjem već da
            je došlo do greške u proizvodnji, pakovanju ili transportu. Rok za
            odgovor na reklamaciju je 8 dana od prijema reklamacije. Rok za
            rešavanje je 15 dana od prijema reklamacije. Povrat robe se u tom
            slučaju vrši na naš trošak a prijava ide u jednostavna tri koraka:
          </Typography>

          <Typography>
            <span className="title">1.</span> Pošaljite Vaš zahtev našem
            operateru na adresu: plemenitaulja@gmail.com
          </Typography>

          <Typography>Obavezno navedite sledeće podatke:</Typography>
          <Container>
            <Typography>-ime i prezime</Typography>
            <Typography>-e-mail adresu</Typography>
            <Typography>-podatci o računu</Typography>
            <Typography>
              -poruku u kojoj ćete opisati razlog reklamacije
            </Typography>
          </Container>
          <br />
          <Typography gutterBottom={true}>
            <span className="title">2.</span> Artikle namenjene za povrat
            adekvatno spakujte kako ne bi došlo do oštećenja u transportu.
          </Typography>
          <Typography gutterBottom={true}>
            <span className="title">3.</span> Nakon što oštećena roba stigne u
            naše prostorije i provere opravdanost reklamacije, ispravan artikal
            biće isporučen na vašu adresu. Ukoliko artikal više nemamo u ponudi,
            biće izvršen povraćaj novca. Zamena oštećenog artikla ide na trošak
            prodavca i vrši se preko odgovarajuće kurirske službe.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReclamationAndReturnOfGoods;
